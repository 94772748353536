import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { LogoColor, LogoDesignSystem, LogoDigital, LogoDigitalCRM, LogoFindRoommate, LogoHseCareer, LogoLang, LogoSmartAssistant, LogoSmartBoss, LogoSmartData, LogoSmartDoc, LogoSmartLms, LogoSmartOffice, LogoSmartPoint, LogoSmartSecurity, LogoSmartScience, LogoSmartReg, LogoSmartEvent } from '@hse-design/react';
import { Playground, Props } from 'docz';
import { ComponentLinks } from '../common';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "logodigital"
    }}>{`LogoDigital`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { LogoColor, LogoDesignSystem, LogoDigital, LogoDigitalCRM, LogoFindRoommate, LogoHseCareer, LogoLang, LogoSmartAssistant, LogoSmartBoss, LogoSmartData, LogoSmartDoc, LogoSmartLms, LogoSmartOffice, LogoSmartPoint, LogoSmartSecurity, LogoSmartScience, LogoSmartReg, LogoSmartEvent } from '@hse-design/react';
`}</code></pre>
    <ComponentLinks figma={'https://www.figma.com/file/cBHD57QcCt9WDT4e7e2B3w/hse_general_library?node-id=582%3A2498'} storybook={'/?path=/story/logo-logodigital--playground'} vueStorybook={'/?path=/story/logo-logodigital--playground'} name={'LogoDigital'} mdxType="ComponentLinks" />
    <h2 {...{
      "id": "описание"
    }}>{`Описание`}</h2>
    <p>{`LogoDigital — компонент логотипа подразделения Вышка.Digital.`}</p>
    <p>{`Помимо логотипа подразделения, для каждого продукта существует свой логотип. Название компонентов начинается с префикса `}<inlineCode parentName="p">{`Logo`}</inlineCode>{`, далее идет название продукта на английском языке в `}<inlineCode parentName="p">{`UpperCamelCase`}</inlineCode>{`.`}</p>
    <p>{`У каждого логотипа есть цветная и черно-белая версия. Для задания цвета передайте в проп `}<inlineCode parentName="p">{`color`}</inlineCode>{` значение `}<inlineCode parentName="p">{`bw`}</inlineCode>{` или `}<inlineCode parentName="p">{`color`}</inlineCode>{`. Для удобства можете воспользоваться перечислением `}<inlineCode parentName="p">{`LogoColor`}</inlineCode></p>
    <p>{`Логотип Вышки.Digital представлен на русском и английском языках. Логотипы продуктов только на английском языке. Для задания языка передайте в проп `}<inlineCode parentName="p">{`lang`}</inlineCode>{` значение `}<inlineCode parentName="p">{`ru`}</inlineCode>{` или `}<inlineCode parentName="p">{`eng`}</inlineCode>{`. Для удобства можете воспользоваться перечислением `}<inlineCode parentName="p">{`LogoLang`}</inlineCode></p>
    <Playground __position={1} __code={'<LogoDigital style={{ height: \'48px\' }} />\n<LogoSmartBoss style={{ height: \'48px\' }} />\n<LogoSmartData style={{ height: \'48px\' }} />\n<LogoSmartLms style={{ height: \'48px\' }} />\n<LogoSmartOffice style={{ height: \'48px\' }} />\n<LogoSmartPoint style={{ height: \'48px\' }} />\n<LogoSmartSecurity style={{ height: \'48px\' }} />\n<LogoFindRoommate style={{ height: \'48px\' }} />\n<LogoSmartDoc style={{ height: \'48px\' }} />\n<LogoDesignSystem style={{ height: \'48px\' }} />\n<LogoSmartAssistant style={{ height: \'48px\' }} />\n<LogoDigitalCRM style={{ height: \'48px\' }} />\n<LogoHseCareer style={{ height: \'48px\' }} />\n<LogoSmartScience style={{ height: \'48px\' }} />\n<LogoSmartReg style={{ height: \'48px\' }} />\n<LogoSmartEvent style={{ height: \'48px\' }} />'} __scope={{
      props,
      DefaultLayout,
      LogoColor,
      LogoDesignSystem,
      LogoDigital,
      LogoDigitalCRM,
      LogoFindRoommate,
      LogoHseCareer,
      LogoLang,
      LogoSmartAssistant,
      LogoSmartBoss,
      LogoSmartData,
      LogoSmartDoc,
      LogoSmartLms,
      LogoSmartOffice,
      LogoSmartPoint,
      LogoSmartSecurity,
      LogoSmartScience,
      LogoSmartReg,
      LogoSmartEvent,
      Playground,
      Props,
      ComponentLinks,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <LogoDigital style={{
        height: '48px'
      }} mdxType="LogoDigital" />
  <LogoSmartBoss style={{
        height: '48px'
      }} mdxType="LogoSmartBoss" />
  <LogoSmartData style={{
        height: '48px'
      }} mdxType="LogoSmartData" />
  <LogoSmartLms style={{
        height: '48px'
      }} mdxType="LogoSmartLms" />
  <LogoSmartOffice style={{
        height: '48px'
      }} mdxType="LogoSmartOffice" />
  <LogoSmartPoint style={{
        height: '48px'
      }} mdxType="LogoSmartPoint" />
  <LogoSmartSecurity style={{
        height: '48px'
      }} mdxType="LogoSmartSecurity" />
  <LogoFindRoommate style={{
        height: '48px'
      }} mdxType="LogoFindRoommate" />
  <LogoSmartDoc style={{
        height: '48px'
      }} mdxType="LogoSmartDoc" />
  <LogoDesignSystem style={{
        height: '48px'
      }} mdxType="LogoDesignSystem" />
  <LogoSmartAssistant style={{
        height: '48px'
      }} mdxType="LogoSmartAssistant" />
  <LogoDigitalCRM style={{
        height: '48px'
      }} mdxType="LogoDigitalCRM" />
  <LogoHseCareer style={{
        height: '48px'
      }} mdxType="LogoHseCareer" />
  <LogoSmartScience style={{
        height: '48px'
      }} mdxType="LogoSmartScience" />
  <LogoSmartReg style={{
        height: '48px'
      }} mdxType="LogoSmartReg" />
  <LogoSmartEvent style={{
        height: '48px'
      }} mdxType="LogoSmartEvent" />
    </Playground>
    <h2 {...{
      "id": "примеры"
    }}>{`Примеры`}</h2>
    <h3 {...{
      "id": "цвет"
    }}>{`Цвет`}</h3>
    <p>{`У каждого логотипа есть цветной и черно-белый вариант отображения.`}</p>
    <p>{`Для задания цвета передайте в проп `}<inlineCode parentName="p">{`color`}</inlineCode>{` значение `}<inlineCode parentName="p">{`bw`}</inlineCode>{` или `}<inlineCode parentName="p">{`color`}</inlineCode>{`. Для удобства можете воспользоваться перечислением `}<inlineCode parentName="p">{`LogoColor`}</inlineCode></p>
    <p>{`По умолчанию логотип цветной`}</p>
    <Playground __position={2} __code={'<div\n  style={{\n    display: \'grid\',\n    maxWidth: \'640px\',\n    width: \'100%\',\n    gridTemplateColumns: \'1fr 1fr\',\n    gridGap: \'12px\',\n  }}\n>\n  <LogoDigital style={{ height: \'48px\' }} color={LogoColor.bw} />\n  <LogoSmartBoss style={{ height: \'48px\' }} color={LogoColor.bw} />\n  <LogoSmartData style={{ height: \'48px\' }} color={LogoColor.bw} />\n  <LogoSmartLms style={{ height: \'48px\' }} color={LogoColor.bw} />\n  <LogoSmartOffice style={{ height: \'48px\' }} color={LogoColor.bw} />\n  <LogoSmartPoint style={{ height: \'48px\' }} color={LogoColor.bw} />\n  <LogoSmartSecurity style={{ height: \'48px\' }} color={LogoColor.bw} />\n  <LogoFindRoommate style={{ height: \'48px\' }} color={LogoColor.bw} />\n  <LogoSmartDoc style={{ height: \'48px\' }} color={LogoColor.bw} />\n  <LogoDesignSystem style={{ height: \'48px\' }} color={LogoColor.bw} />\n  <LogoSmartAssistant style={{ height: \'48px\' }} color={LogoColor.bw} />\n  <LogoDigitalCRM style={{ height: \'48px\' }} color={LogoColor.bw} />\n  <LogoHseCareer style={{ height: \'48px\' }} color={LogoColor.bw} />\n  <LogoSmartScience style={{ height: \'48px\' }} color={LogoColor.bw} />\n  <LogoSmartReg style={{ height: \'48px\' }} color={LogoColor.bw} />\n  <LogoSmartEvent style={{ height: \'48px\' }} color={LogoColor.bw} />\n</div>'} __scope={{
      props,
      DefaultLayout,
      LogoColor,
      LogoDesignSystem,
      LogoDigital,
      LogoDigitalCRM,
      LogoFindRoommate,
      LogoHseCareer,
      LogoLang,
      LogoSmartAssistant,
      LogoSmartBoss,
      LogoSmartData,
      LogoSmartDoc,
      LogoSmartLms,
      LogoSmartOffice,
      LogoSmartPoint,
      LogoSmartSecurity,
      LogoSmartScience,
      LogoSmartReg,
      LogoSmartEvent,
      Playground,
      Props,
      ComponentLinks,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div style={{
        display: 'grid',
        maxWidth: '640px',
        width: '100%',
        gridTemplateColumns: '1fr 1fr',
        gridGap: '12px'
      }}>
    <LogoDigital style={{
          height: '48px'
        }} color={LogoColor.bw} mdxType="LogoDigital" />
    <LogoSmartBoss style={{
          height: '48px'
        }} color={LogoColor.bw} mdxType="LogoSmartBoss" />
    <LogoSmartData style={{
          height: '48px'
        }} color={LogoColor.bw} mdxType="LogoSmartData" />
    <LogoSmartLms style={{
          height: '48px'
        }} color={LogoColor.bw} mdxType="LogoSmartLms" />
    <LogoSmartOffice style={{
          height: '48px'
        }} color={LogoColor.bw} mdxType="LogoSmartOffice" />
    <LogoSmartPoint style={{
          height: '48px'
        }} color={LogoColor.bw} mdxType="LogoSmartPoint" />
    <LogoSmartSecurity style={{
          height: '48px'
        }} color={LogoColor.bw} mdxType="LogoSmartSecurity" />
    <LogoFindRoommate style={{
          height: '48px'
        }} color={LogoColor.bw} mdxType="LogoFindRoommate" />
    <LogoSmartDoc style={{
          height: '48px'
        }} color={LogoColor.bw} mdxType="LogoSmartDoc" />
    <LogoDesignSystem style={{
          height: '48px'
        }} color={LogoColor.bw} mdxType="LogoDesignSystem" />
    <LogoSmartAssistant style={{
          height: '48px'
        }} color={LogoColor.bw} mdxType="LogoSmartAssistant" />
    <LogoDigitalCRM style={{
          height: '48px'
        }} color={LogoColor.bw} mdxType="LogoDigitalCRM" />
    <LogoHseCareer style={{
          height: '48px'
        }} color={LogoColor.bw} mdxType="LogoHseCareer" />
    <LogoSmartScience style={{
          height: '48px'
        }} color={LogoColor.bw} mdxType="LogoSmartScience" />
    <LogoSmartReg style={{
          height: '48px'
        }} color={LogoColor.bw} mdxType="LogoSmartReg" />
    <LogoSmartEvent style={{
          height: '48px'
        }} color={LogoColor.bw} mdxType="LogoSmartEvent" />
  </div>
    </Playground>
    <h3 {...{
      "id": "язык"
    }}>{`Язык`}</h3>
    <p>{`Логотип Вышки.Digital представлен на русском и английском языках. Логотипы продуктов только на английском языке.`}</p>
    <p>{`Для задания языка передайте в проп `}<inlineCode parentName="p">{`lang`}</inlineCode>{` значение `}<inlineCode parentName="p">{`ru`}</inlineCode>{` или `}<inlineCode parentName="p">{`eng`}</inlineCode>{`. Для удобства можете воспользоваться перечислением `}<inlineCode parentName="p">{`LogoLang`}</inlineCode></p>
    <Playground __position={3} __code={'<div>\n  <LogoDigital style={{ height: \'48px\' }} />\n  <LogoDigital style={{ height: \'48px\' }} lang={LogoLang.eng} />\n</div>\n<LogoSmartBoss lang={LogoLang.eng} style={{ height: \'48px\' }} />\n<LogoSmartData lang={LogoLang.eng} style={{ height: \'48px\' }} />\n<LogoSmartLms lang={LogoLang.eng} style={{ height: \'48px\' }} />\n<LogoSmartOffice lang={LogoLang.eng} style={{ height: \'48px\' }} />\n<LogoSmartPoint lang={LogoLang.eng} style={{ height: \'48px\' }} />\n<LogoSmartSecurity lang={LogoLang.eng} style={{ height: \'48px\' }} />\n<LogoFindRoommate lang={LogoLang.eng} style={{ height: \'48px\' }} />\n<LogoSmartDoc lang={LogoLang.eng} style={{ height: \'48px\' }} />\n<LogoDesignSystem lang={LogoLang.eng} style={{ height: \'48px\' }} />\n<LogoSmartAssistant lang={LogoLang.eng} style={{ height: \'48px\' }} />\n<LogoDigitalCRM lang={LogoLang.eng} style={{ height: \'48px\' }} />\n<LogoHseCareer lang={LogoLang.eng} style={{ height: \'48px\' }} />\n<LogoSmartScience lang={LogoLang.eng} style={{ height: \'48px\' }} />\n<LogoSmartReg lang={LogoLang.eng} style={{ height: \'48px\' }} />\n<LogoSmartEvent lang={LogoLang.eng} style={{ height: \'48px\' }} />'} __scope={{
      props,
      DefaultLayout,
      LogoColor,
      LogoDesignSystem,
      LogoDigital,
      LogoDigitalCRM,
      LogoFindRoommate,
      LogoHseCareer,
      LogoLang,
      LogoSmartAssistant,
      LogoSmartBoss,
      LogoSmartData,
      LogoSmartDoc,
      LogoSmartLms,
      LogoSmartOffice,
      LogoSmartPoint,
      LogoSmartSecurity,
      LogoSmartScience,
      LogoSmartReg,
      LogoSmartEvent,
      Playground,
      Props,
      ComponentLinks,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div>
    <LogoDigital style={{
          height: '48px'
        }} mdxType="LogoDigital" />
    <LogoDigital style={{
          height: '48px'
        }} lang={LogoLang.eng} mdxType="LogoDigital" />
  </div>
  <LogoSmartBoss lang={LogoLang.eng} style={{
        height: '48px'
      }} mdxType="LogoSmartBoss" />
  <LogoSmartData lang={LogoLang.eng} style={{
        height: '48px'
      }} mdxType="LogoSmartData" />
  <LogoSmartLms lang={LogoLang.eng} style={{
        height: '48px'
      }} mdxType="LogoSmartLms" />
  <LogoSmartOffice lang={LogoLang.eng} style={{
        height: '48px'
      }} mdxType="LogoSmartOffice" />
  <LogoSmartPoint lang={LogoLang.eng} style={{
        height: '48px'
      }} mdxType="LogoSmartPoint" />
  <LogoSmartSecurity lang={LogoLang.eng} style={{
        height: '48px'
      }} mdxType="LogoSmartSecurity" />
  <LogoFindRoommate lang={LogoLang.eng} style={{
        height: '48px'
      }} mdxType="LogoFindRoommate" />
  <LogoSmartDoc lang={LogoLang.eng} style={{
        height: '48px'
      }} mdxType="LogoSmartDoc" />
  <LogoDesignSystem lang={LogoLang.eng} style={{
        height: '48px'
      }} mdxType="LogoDesignSystem" />
  <LogoSmartAssistant lang={LogoLang.eng} style={{
        height: '48px'
      }} mdxType="LogoSmartAssistant" />
  <LogoDigitalCRM lang={LogoLang.eng} style={{
        height: '48px'
      }} mdxType="LogoDigitalCRM" />
  <LogoHseCareer lang={LogoLang.eng} style={{
        height: '48px'
      }} mdxType="LogoHseCareer" />
  <LogoSmartScience lang={LogoLang.eng} style={{
        height: '48px'
      }} mdxType="LogoSmartScience" />
  <LogoSmartReg lang={LogoLang.eng} style={{
        height: '48px'
      }} mdxType="LogoSmartReg" />
  <LogoSmartEvent lang={LogoLang.eng} style={{
        height: '48px'
      }} mdxType="LogoSmartEvent" />
    </Playground>
    <h2 {...{
      "id": "пропы"
    }}>{`Пропы`}</h2>
    <Props of={LogoDigital} mdxType="Props" />
    <p>{`Все остальные свойства пробрасываются на корневой элемент `}<inlineCode parentName="p">{`svg`}</inlineCode>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      